import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { resetPassword } from "../../actions/authAction";
import logo from '../../images/LOGO.png';
import TitleBar from "../TitleBar";

const ResetPassword = ({ location }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })
    const [msg,setMsg] = useState("")

    
    const onInputChange = (e) => {
        setMsg("")
        setPassword({ ...password, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        if(password.password.includes(" ")){
            setMsg("Password can not contain spaces.")
          }else{
        setLoader(true);
        dispatch(resetPassword(password, setLoader, history));
          }
    }

    return (
        <>

            <TitleBar title="Reset Password" />

            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2>Welcome <span>Back!</span></h2>
                                <p>Create Incredible Videos On Scale Using AI & Just a Keyword!
                                    Dominate Your Niche With Short Engaging Videos!
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Reset</span> Password</h2>
                                    <form method="post" onSubmit={(e) => onFormSubmit(e)}>
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input type="password" name="password" className="form-control"
                                                placeholder="************"
                                                required onChange={(e) => onInputChange(e)}
                                                minLength={6}
                                                maxLength={32}
                                            />
                                            {msg && <div style={{ color: "white" }}>{msg}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" name="confirm_password" className="form-control"
                                                placeholder="************" required
                                                onChange={(e) => onInputChange(e)}
                                                minLength={6}
                                                maxLength={32}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Login ? <Link to="/login" > Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginFooter />
        </>
    )
}


export default ResetPassword;